import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAPI } from "./API";

const LoginRedirector = () => {
    const api = useAPI();
    const location = useLocation();

    if (api.type !== "logged" && location.pathname !== "/") {
        return <Navigate to="/" />;
    } else if (api.type !== 'unlogged' && location.pathname === '/') {
        return <Navigate to="/dashboard" />;
    }
    return <Outlet />;
};

export default LoginRedirector;
