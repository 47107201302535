import React, { useState } from "react";
import { useAPI } from "../api/API";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const api = useAPI();

    const login = async (e) => {
        e.preventDefault();

        try {
            await api.login(username, password);
        } catch (error) {
            console.error(error);
            if (error.response) {
                setError("Неверный логин или пароль"); // Пример сообщения об ошибке
            }
        }
    };

    return (
        <div className="auth-container">
            <div className="auth-form">
                <h1 className="auth-title">Авторизация</h1>
                {error && <div className="message-box visible">{error}</div>}
                <form className="form" onSubmit={login}>
                    <div className="form__input">
                        <label htmlFor="login">Логин</label>

                        <input
                            type="text"
                            placeholder="Логин"
                            required
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="form__input">
                        <label htmlFor="password">Пароль</label>

                        <input
                            type="password"
                            placeholder="Пароль"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <button type="submit" className="form__button">
                        ВОЙТИ
                    </button>
                </form>
                <div className="instruction">
                    <a href="https://cdn.quadrosim.ru/guides/lk-instruction.pdf">
                        Инструкция по работе с личным кабинетом
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Login;
