import React from "react";
import { useAPI } from "../api/API";

const Navbar = () => {
    const api = useAPI();

    const logout = async (e) => {
        e.preventDefault();
        await api.logout();
    };

    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <img alt="" src="/images/Logo-header.svg" />
                <img alt="Квадросим" src="/images/logo-text.png" />
            </div>
            <div className="navbar-links">
                <a href="/" onClick={logout}>
                    Выйти
                </a>
            </div>
        </nav>
    );
};

export default Navbar;
