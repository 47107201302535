import * as React from "react";
import { useEffect, useState } from "react";
import { useAPI } from "../api/API";

const Dashboard = () => {
    const [globalVersion, setGlobalVersion] = useState("Квадросим");
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const api = useAPI();

    useEffect(() => {
        api.distributionInfo().then((data) => {
            setData(data.distributions);
            setGlobalVersion(data.global_version);
        }, console.error);

        api.distributionInfoVR().then((data) => {
            setData2(data.distributions);
        }, console.error);

        api.distributionInfoAutosim().then((data) => {
            setData3(data.distributions);
        }, console.error);
    }, [api]);

    return (
        <>
            <div className="background"></div>
            <div className="dash-container">
                <div className="notch-container">
                    <div className="notch"></div>
                    <div className="container full-height">
                        <h1>
                            Для начала работы с Квадросимом необходимо выполнить
                            следующие действия:
                        </h1>
                        <p>
                            <ul>
                                <li>1. Скачать архив с симулятором</li>
                                <li>2. Распаковать архив в желаемую папку</li>
                                <li>
                                    3. Запустить симулятор через исполняемый
                                    файл в папке с симулятором
                                </li>
                                <li>
                                    4.{" "}
                                    <a
                                        href="https://cdn-s3.quadrosim.ru/guides/%D0%9A%D0%B2%D0%B0%D0%B4%D1%80%D0%BE%D1%81%D0%B8%D0%BC_v1_8_%D0%A0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE_%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F.pdf"
                                        className="instruction-main"
                                    >
                                        Познакомьтесь с руководством
                                        пользователя
                                    </a>
                                </li>
                            </ul>
                        </p>
                        <p>
                            В случае, если на одном компьютере необходимо
                            установить две версии Квадросима, в том числе разных
                            лицензий, воспользуйтесь установочным файлом
                            Квадросим Экстра
                        </p>

                        <p>
                            Обратите внимание, что вторая версия симулятора
                            будет работать обособленно от первой, включая данные
                            лицензии, профили пользователя, импортируемый и
                            экспортируемый контент и т.д.
                        </p>
                    </div>
                    <div className="notch-after"></div>
                </div>
                <div className="table-title">
                    <span className="t-title">{globalVersion}</span>
                </div>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Версия</th>
                                <th>ОС</th>
                                <th>Тип Файла</th>
                                <th>Ссылка на скачивание</th>
                                <th>Вес</th>
                                <th>Инструкция по установке</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((o) => (
                                <tr key={`${o.version}-${o.os}`}>
                                    <td>{o.version}</td>
                                    <td>{o.os}</td>
                                    <td>{o.extension}</td>
                                    <td>
                                        <a href={o.get_distributive_url}>
                                            Скачать
                                        </a>
                                    </td>
                                    <td>{o.file_weight}</td>
                                    <td>
                                        <a href={o.get_instruction_url}>
                                            Инструкция
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="table-title">
                    <span className="t-title">
                        Бета-версия Квадросима для использования вместе с очками
                        виртуальной реальности
                    </span>
                </div>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Версия</th>
                                <th>ОС</th>
                                <th>Тип Файла</th>
                                <th>Ссылка на скачивание</th>
                                <th>Вес</th>
                                <th>Инструкция по установке</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data2.map((o) => (
                                <tr key={`${o.version}-${o.os}`}>
                                    <td>{o.version}</td>
                                    <td>{o.os}</td>
                                    <td>{o.extension}</td>
                                    <td>
                                        <a href={o.get_distributive_url}>
                                            Скачать
                                        </a>
                                    </td>
                                    <td>{o.file_weight}</td>
                                    <td>
                                        <a href={o.get_instruction_url}>
                                            Инструкция
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="table-title">
                    <span className="t-title">Бета-версия Автосима</span>
                </div>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Версия</th>
                                <th>ОС</th>
                                <th>Тип Файла</th>
                                <th>Ссылка на скачивание</th>
                                <th>Вес</th>
                                <th>Инструкция по установке</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data3.map((o) => (
                                <tr key={`${o.version}-${o.os}`}>
                                    <td>{o.version}</td>
                                    <td>{o.os}</td>
                                    <td>{o.extension}</td>
                                    <td>
                                        <a href={o.get_distributive_url}>
                                            Скачать
                                        </a>
                                    </td>
                                    <td>{o.file_weight}</td>
                                    <td>
                                        <a href={o.get_instruction_url}>
                                            Инструкция
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
